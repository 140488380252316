<template>
  <v-menu v-model="termSelectMenu" :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on" @click="termFilter = ''">
        {{ selectedTermLabel }}
        <v-icon right>fal fa-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text style="padding-top:0;padding-bottom:0">
        <v-text-field v-model="termFilter" ref="termFilterRef" append-icon="fal fa-search" label="Term Filter"></v-text-field>
      </v-card-text>
      <v-list style="max-height:400px" class="mini-scrollbar">
        <v-list-item v-for="item in filteredTermsList" :key="item.code" @click="selectTerm(item)">
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'
export default {
  props: {
    termQuery: {
      type: Object,
      default: () => {
        return {
          end: { $gte: new Date() }
        }
      }
    }
  },
  setup (props, { root, emit }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => user.value.roles)
    const selectedTerm = computed(() => root.$store.state.selectedTerm)
    const selectedTermLabel = computed(() => root.$store.state.selectedTermLabel)
    const termsList = ref([])
    const termSelectMenu = ref(false)
    const termFilter = ref('')
    const termFilterRef = ref(null)
    const filteredTermsList = computed(() => {
      if (termFilter.value === '') return termsList.value
      let arr = []
      let regEx = new RegExp(termFilter.value, 'ig')
      termsList.value.forEach((row) => {
        if (regEx.test(row.label) || regEx.test(row.term)) arr.push(row)
      })
      return arr
    })

    onMounted(async () => {
      let query = { ...props.termQuery, $sort: { start: 1, end: 1, label: 1 }, $limit: 20 }
      root.$feathers.service('system/term').find({ query }).then(({ data }) => {
        termsList.value = data
        for (let i = 0; i < data.length; i++) {
          if (data[i].type === 'Traditional') {
            emit('selectTerm', data[i])
            break
          }
        }
      })
    })

    function selectTerm (obj) {
      root.$store.commit('setTerm', obj)
      emit('selectTerm', obj)
      termSelectMenu.value = false
    }

    return {
      user,
      roles,
      selectedTerm,
      selectedTermLabel,
      termsList,
      termSelectMenu,
      termFilter,
      termFilterRef,
      filteredTermsList,
      selectTerm
    }
  }
}
</script>
